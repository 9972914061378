













































import Base from '@/mixins/Base.vue';
import { IInfringement, IInfringementResponse } from '@/interfaces/infringement';

const component = Base.extend({
    data() {
        return {
            active: false,
            confirmation: '',
            infringement: undefined as any | IInfringement,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.infringement.title;
        },
    },
    mounted() {
        this.active = true;
        this.getinfringement();
    },
    methods: {
        getinfringement(): void {
            this.get<IInfringementResponse>(`infringements/${this.$route.params.id}`).then(({ data }) => {
                this.infringement = data.data;
            });
        },
        save(): void {
            if (this.validated) {
                this.delete(`infringements/${this.infringement.id}`).then(() => {
                    this.$router.push({ name: 'infringements.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
